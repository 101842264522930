import React from 'react';
import { useShop } from '@src/contexts/Shop';
import Button from '@src/components/buttons/Button';

export function AddToCart({ variantId, quantity, available, price }) {
    const { addItem, loading } = useShop();

    function addToCart(e) {
        e.preventDefault();
        addItem(variantId, quantity);
    }

    return (
        <Button
            onClick={addToCart}
            classes="button--large"
            disabled={!available || loading}
            copy={available ? `Add to Cart ${price}` : 'Out of Stock'}
        />
    );
}

export default AddToCart;
