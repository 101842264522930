import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({
    description,
    lang,
    meta,
    title,
    twitterImage,
    facebookImage,
    keywords,
    canonical,
    landing,
    modifiedTime,
    url,
}) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            title={metaTitle}
            htmlAttributes={{ lang }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: 'keywords',
                    content: keywords,
                },
                {
                    property: `og:locale`,
                    content: `en_US`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: facebookImage,
                },
                {
                    property: `og:type`,
                    content: landing ? `website` : `article`,
                },
                {
                    property: `article:publisher`,
                    content: `https://www.facebook.com/omiamifestival/`,
                },
                {
                    property: `article:modified_time`,
                    content: modifiedTime,
                },
                {
                    property: `og:url`,
                    content: url,
                },
                {
                    property: `og:site_name`,
                    content: `O, Miami`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:creator`,
                    content: `@omiamifestival`,
                },
                {
                    name: `twitter:site`,
                    content: `@omiamifestival`,
                },
                {
                    name: `twitter:label1`,
                    content: `Written by`,
                },
                {
                    name: `twitter:data1`,
                    content: `@omiamifestival`,
                },
                {
                    name: `twitter:image`,
                    content: twitterImage,
                },
                {
                    rel: 'canonical',
                    href: canonical,
                },
            ].concat(meta)}
            link={[{ rel: 'icon', type: 'image/svg', href: '/icon.svg' }]}>
            <script type="application/ld+json">
                {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://omiami.org",
                            "name": "O, Miami",
                            "description": "",
                            "logo": "",
                            "image": "",
                            "telephone": "",
                            "email": "",
                            "areaServed": "USA",
                            "sameAs": [
                                "https://www.facebook.com/omiamifestival/",
                                "https://www.instagram.com/omiamifestival/",
                                "https://twitter.com/omiamifestival"
                            ],
                            "founder": {
                                "@type": "Person",
                                "name": ""
                            },
                            "foundingLocation": {
                                "@type": "Place",
                                "name": "USA"
                            }
                        }
                    `}
            </script>
            <script>
                {`            
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '901256900505459');
                    fbq('track', 'PageView');
                `}
            </script>
            <noscript>
                {`<img
                    height="1"
                    width="1"
                    style="display:none"
                    src="https://www.facebook.com/tr?id=901256900505459&ev=PageView&noscript=1"
                />`}
            </noscript>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-1C5HB0VM5T" />
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                  
                    gtag('config', 'G-1C5HB0VM5T');
                `}
            </script>
        </Helmet>
    );
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

export default Seo;
