import React from 'react';

export function NumericInput({ value, classes, disabled, onIncrement, onDecrement, onChange }) {
    return (
        <div className="flex">
            <button
                disabled={disabled}
                aria-label="Decrement"
                onClick={onDecrement}
                type="button"
                className="large link px-8px cursor"
                style={{ flex: '0 1 auto', fontSize: '32px' }}>
                <span>-</span>
            </button>
            <input
                disabled={disabled}
                type="numeric"
                className={`large link center-text ${classes}`}
                style={{
                    flex: '1 1 auto',
                    width: '100%',
                    maxWidth: '80px',
                    marginLeft: '0.6em',
                    marginRight: '0.6em',
                }}
                min="1"
                max="99"
                value={value}
                onChange={onChange}
            />
            <button
                disabled={disabled}
                aria-label="Increment"
                onClick={onIncrement}
                type="button"
                className="large link px-8px cursor"
                style={{ flex: '0 1 auto', fontSize: '32px' }}>
                <span>+</span>
            </button>
        </div>
    );
}

export default NumericInput;
