import React, { useState, useEffect } from 'react';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import { NumericInput } from '@src/components/numericInput/NumericInput';
import Loader from '@src/components/Loader';
import ImageCarousel from '@src/components/ImageCarousel';
import classNames from 'classnames';
import { AddToCart } from './AddToCart';

export function Product({ data: entry }) {
    const { queryProduct } = useShop();
    const productId = entry.storefrontId; // encode id for storefront api

    const [isLoading, setIsLoading] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const [product, setProduct] = useState({});
    const [variant, setVariant] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState('');

    const handleOptionChange = (e, index) => {
        const { value } = e.target;
        if (value === '') return;

        const selectedVariant = product.variants?.edges?.find(
            ({ node: { selectedOptions } }) =>
                selectedOptions[index].name === variant?.selectedOptions[index].name &&
                selectedOptions[index].value === value
        )?.node;

        if (selectedVariant) {
            setVariant({ ...selectedVariant });
        }
    };

    useEffect(() => {
        if (productId && productId.length > 1)
            queryProduct(productId)
                .then(fetchedProduct => {
                    if (!fetchedProduct) return;
                    setProduct(fetchedProduct);
                    setVariant({ ...fetchedProduct.variants?.edges[0]?.node });
                    setIsLoading(false);
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    setIsLoading(false);
                });
    }, [productId]);

    useEffect(() => {
        if (variant?.priceV2) {
            setPrice(
                formatPrice(
                    variant.priceV2.currencyCode,
                    Math.max(1, variant.priceV2.amount * quantity)
                )
            );
        }
    }, [variant, quantity]);

    const productDetails = (
        <div className="flex flex-wrap">
            {product && product.variants?.edges?.length > 1 && variant.selectedOptions?.length > 0
                ? product.options.map(({ id, name, values }, optionIndex) => (
                      <div className="mb-42px" key={[variant?.id, id]}>
                          <h4 className="h4">{name}</h4>
                          <div className="relative arrow-down">
                              <select
                                  onChange={e => handleOptionChange(e, optionIndex)}
                                  className="button button--dropdown m-0">
                                  {values.map(value => (
                                      <option
                                          key={value}
                                          value={value}
                                          selected={
                                              variant.selectedOptions[optionIndex].value === value
                                          }>
                                          {value}
                                      </option>
                                  ))}
                              </select>
                          </div>
                      </div>
                  ))
                : null}
            {variant.available && (
                <div
                    className={classNames(
                        product.variants?.edges?.length > 1
                            ? 'mb-[32px] sm:mx-[36px]'
                            : 'mr-[48px] mb-[32px]'
                    )}>
                    <h4 className="h4">Quantity</h4>
                    <NumericInput
                        aria-label="Quantity"
                        onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
                        onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
                        onChange={e => setQuantity(e.currentTarget.value)}
                        value={quantity}
                        min="1"
                        max="20"
                    />
                </div>
            )}
            <div>
                <AddToCart
                    variantId={variant.id}
                    quantity={quantity}
                    available={variant.available}
                    price={price}
                />
            </div>
        </div>
    );

    return (
        <div className="product">
            {entry.multipleImages.length > 0 ? (
                <div style={{ marginBottom: 88 }} className="relative">
                    <ImageCarousel images={entry.multipleImages} />
                </div>
            ) : null}

            <div className="grid grid--1-2">
                <div className="rich-text">
                    <h2>{entry.title}</h2>
                    <p>{entry.plainText}</p>
                </div>
                <div>
                    {entry.richTextStandard ? (
                        <div
                            className="rich-text mb-42px"
                            dangerouslySetInnerHTML={{ __html: entry.richTextStandard }}
                        />
                    ) : null}

                    {product !== null && (
                        <div>
                            {isLoading ? (
                                <div className="relative flex full justify-center">
                                    <Loader />
                                </div>
                            ) : (
                                productDetails
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Product;
